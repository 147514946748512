import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cdaStore, userToolStore, parceriaStore, parceriaCupomStore, userActionStore, } from '@/store';
import _ from 'lodash';
import { newParceria } from '@/interfaces/parceria';
import ParceriaCupomCrudComponent from '@/components/ParceriaCupomCrudComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Parceria', value: 'parceria_nome' },
            { text: 'Nome', value: 'nome' },
            { text: 'Idade', value: 'idade' },
            { text: this.getTextCDAResponsavel, value: 'cda_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Ações', value: 'actions' },
        ];
        this.expandedHeaders = [
            { text: 'Data da ligação', value: 'created_at' },
            { text: this.getTextCDAResponsavel, value: 'cda_nome' },
            { text: 'Status', value: 'status' },
            { text: 'Agendamento', value: 'agendamento' },
            { text: 'Observação', value: 'observacao' },
        ];
        this.parceria = {
            data_inicio: '',
            data_termino: '',
            nome: 'Todos',
            descricao: '',
            company_id: 0,
            id: 0,
        };
        this.loading = true;
        this.search = '';
        this.selectedHistorico = {};
        this.selectedItem = {};
        this.showObservacao = false;
        this.selectedTab = 5;
        this.parceriaCuponsItems = [];
        this.movidoParaCda = { id: -1 };
    }
    get getTextCDAResponsavel() {
        // @ts-ignore
        return `${this.getNomeCDA()} responsável `;
    }
    get userActionDistribuirCuponsEntreCdasAllow() {
        return userActionStore.distribuirCuponsEntreCdasAllow;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_delete;
        }
    }
    get cdaOptions() {
        const cdas = cdaStore.cdas;
        // @ts-ignore
        cdas.unshift({
            id: 0,
            // @ts-ignore
            full_name: this.getNomeCDA() + 's ALEATÓRIOS',
        });
        return cdas;
    }
    get parceriasOptions() {
        const obj = { ...newParceria(), id: 0 };
        obj.nome = 'Todos';
        const options = [...parceriaStore.parcerias];
        options.unshift(obj);
        return options;
    }
    get totalSelecionado() {
        const cupons = this.parceriaCuponsItems.filter((item) => {
            return item.selected;
        });
        return cupons.length;
    }
    async onParceriaUpdate(val) {
        if (val) {
            if (val.id === 0) {
                this.parceriaCuponsItems = [...parceriaCupomStore.parceriaCupons];
                return;
            }
            this.parceriaCuponsItems = _.filter(parceriaCupomStore.parceriaCupons, 
            // @ts-ignore
            (item) => item.parceria_id == val.id);
        }
    }
    showCommentDialog(item) {
        this.selectedHistorico = item;
        this.showObservacao = true;
    }
    statusString(item) {
        let value = '';
        switch (item) {
            case 1:
                value = 'Ligou';
                break;
            case 2:
                value = 'Não atendeu';
                break;
            case 3:
                value = 'Agendado';
                break;
            case 4:
                value = 'Reagendado';
                break;
            case 5:
                value = 'Confirmado';
                break;
            case 6:
                value = 'Desistiu';
            case 7:
                value = 'Sem interesse';
        }
        return value;
    }
    async moverPara() {
        this.$swal({
            title: 'Confirma a movimentação dos cupons?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cupons = this.parceriaCuponsItems.filter((item) => {
                    if (item.selected) {
                        item.selected = false;
                        return item;
                    }
                });
                if (cupons.length === 0) {
                    this.$swal({
                        text: 'Para distribuir você deve selecionar ao menos um cupom!',
                        icon: 'error',
                    });
                    return 1;
                }
                const payload = {
                    cupons,
                    cda_id: this.movidoParaCda,
                };
                await cdaStore.distribuirCupons(payload);
                await parceriaCupomStore.getParceriaCupons();
                this.parceriaCuponsItems = parceriaCupomStore.parceriaCupons;
            }
        });
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do cupom?',
            text: 'Após exclusão o cupom não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await parceriaCupomStore.deleteParceriaCupom(id);
                // @ts-ignore
                await parceriaCupomStore.getParceriaCupons();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await parceriaStore.getParcerias();
        await parceriaCupomStore.getParceriaCupons();
        this.parceriaCuponsItems = parceriaCupomStore.parceriaCupons;
        await cdaStore.getCdas();
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.parceria = { id: this.$router.currentRoute.params.id };
        }
        else {
            // @ts-ignore
            this.parceria = { id: 0 };
        }
        this.headers.unshift({
            text: '',
            value: 'selected',
        });
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-cupons-edit', params: { id } });
    }
};
__decorate([
    Watch('parceria')
], List.prototype, "onParceriaUpdate", null);
List = __decorate([
    Component({
        components: {
            ParceriaCupomCrudComponent,
            CustomTabsComercialComponent,
        },
    })
], List);
export default List;
